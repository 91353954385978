var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "content" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "form-block" }, [
        _vm._m(0),
        _c("div", { staticClass: "form" }, [
          _c(
            "p",
            [
              _vm._v(
                "The page you are searching is not avaliable or is removed click "
              ),
              _c("router-link", { attrs: { to: "/" } }, [_vm._v("here")]),
              _vm._v(" to go to main page")
            ],
            1
          )
        ]),
        _vm._m(1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { src: require("./../../assets/images/logo.png"), alt: "BSH" }
        })
      ]),
      _c("div", { staticClass: "brand-logos d-flex" }, [
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("./../../assets/images/bosch-logo.png"),
              alt: "Bosch"
            }
          })
        ]),
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("./../../assets/images/siemens-logo.png"),
              alt: "siemens"
            }
          })
        ]),
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("./../../assets/images/neff.png"),
              alt: "neff"
            }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("p", [
        _c(
          "a",
          {
            staticClass: "mr-3",
            attrs: {
              href: "https://www.bosch-home.co.uk/store/terms-of-website-use"
            }
          },
          [_vm._v("Term of use ")]
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.bosch-home.co.uk/data-protection-information"
            }
          },
          [_vm._v("Data Protection Policy")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }