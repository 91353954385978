<template>
  <main class="content">
    <div class="container">
      <div class="form-block">
        <header>
          <div class="logo">
            <img src="./../../assets/images/logo.png"  alt="BSH"/>
          </div>
          <div class="brand-logos d-flex">
            <div class="logo">
              <img src="./../../assets/images/bosch-logo.png" alt="Bosch" />
            </div>
            <div class="logo">
              <img src="./../../assets/images/siemens-logo.png" alt="siemens" />
            </div>
            <div class="logo">
              <img src="./../../assets/images/neff.png" alt="neff" />
            </div>
          </div>
        </header>
        <div class="form">
          <p>The page you are searching is not avaliable or is removed click <router-link to="/">here</router-link> to go to main page</p>
        </div>
        <footer>
          <p>
            <a href="https://www.bosch-home.co.uk/store/terms-of-website-use" class="mr-3">Term of use </a>
            <a href="https://www.bosch-home.co.uk/data-protection-information">Data Protection Policy</a>
          </p>
        </footer>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "NotFound",
  components: {},
  props: {},
  data: function () {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>